import { Injectable } from '@angular/core';
import { ParamMap } from '@angular/router';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { isNil, cloneDeep } from 'lodash-es';
import { ToastrService } from 'ngx-toastr';

import { WavelengthShellService } from 'wavelength-ui';
import { EModalSize, EToasterType, EToasterPosition } from 'wavelength-ui';


import { app_homeComponent } from './app.home.component';

import { Utilities_ShellService } from './Utilities.shell.service';
import { Attachments_ShellService } from './Attachments.shell.service';
import { WarehouseTransfers_ShellService } from './WarehouseTransfers.shell.service';
import { Notifications_ShellService } from './Notifications.shell.service';
import { PackVerification_ShellService } from './PackVerification.shell.service';
import { InventoryTransfers_ShellService } from './InventoryTransfers.shell.service';
import { Reports_ShellService } from './Reports.shell.service';
import { PrintNode_ShellService } from './PrintNode.shell.service';
import { AsnOrders_ShellService } from './AsnOrders.shell.service';
import { SalesOrders_ShellService } from './SalesOrders.shell.service';
import { FootPrintApiManager_ShellService } from './FootPrintApiManager.shell.service';
import { Returns_ShellService } from './Returns.shell.service';
import { Cartonization_ShellService } from './Cartonization.shell.service';

@Injectable({ providedIn: 'root' })
export class app_ShellService extends WavelengthShellService  {
  constructor(
    dialog : MatDialog,
    toastr: ToastrService,
    public Utilities: Utilities_ShellService,
    public Attachments: Attachments_ShellService,
    public WarehouseTransfers: WarehouseTransfers_ShellService,
    public Notifications: Notifications_ShellService,
    public PackVerification: PackVerification_ShellService,
    public InventoryTransfers: InventoryTransfers_ShellService,
    public Reports: Reports_ShellService,
    public PrintNode: PrintNode_ShellService,
    public AsnOrders: AsnOrders_ShellService,
    public SalesOrders: SalesOrders_ShellService,
    public FootPrintApiManager: FootPrintApiManager_ShellService,
    public Returns: Returns_ShellService,
    public Cartonization: Cartonization_ShellService,
  ) {
    super(dialog, toastr);
  }

  public app: app_ShellService = this;

  public openhome(replaceCurrentView?: boolean) {
    WavelengthShellService.openViewRequest$.next(
      {
        title: 'FootPrint Api',
        referenceName: 'home',
        component: app_homeComponent,
        replaceCurrentView: replaceCurrentView,
        
      }
    );
  }

  public openhomeDialog(
    
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<void> {
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Standard;
    }

    return this.openDialog(
      app_homeComponent,
      'FootPrint Api',
      mode,
      dialogSize
    )
  }

  public override getComponentInformation(referenceName: string, params: ParamMap): { title: string, component: any, inParams: any } {
    if (referenceName === 'home') {
      const title = 'FootPrint Api';
      const component = app_homeComponent;
      const inParams = null;

      return { title: title, component: component, inParams: inParams };
    }


    let result = null;
    result = this.Utilities.getComponentInformation(referenceName, params);
    if (result) {
      return result;
    }
    result = this.Attachments.getComponentInformation(referenceName, params);
    if (result) {
      return result;
    }
    result = this.WarehouseTransfers.getComponentInformation(referenceName, params);
    if (result) {
      return result;
    }
    result = this.Notifications.getComponentInformation(referenceName, params);
    if (result) {
      return result;
    }
    result = this.PackVerification.getComponentInformation(referenceName, params);
    if (result) {
      return result;
    }
    result = this.InventoryTransfers.getComponentInformation(referenceName, params);
    if (result) {
      return result;
    }
    result = this.Reports.getComponentInformation(referenceName, params);
    if (result) {
      return result;
    }
    result = this.PrintNode.getComponentInformation(referenceName, params);
    if (result) {
      return result;
    }
    result = this.AsnOrders.getComponentInformation(referenceName, params);
    if (result) {
      return result;
    }
    result = this.SalesOrders.getComponentInformation(referenceName, params);
    if (result) {
      return result;
    }
    result = this.FootPrintApiManager.getComponentInformation(referenceName, params);
    if (result) {
      return result;
    }
    result = this.Returns.getComponentInformation(referenceName, params);
    if (result) {
      return result;
    }
    result = this.Cartonization.getComponentInformation(referenceName, params);
    if (result) {
      return result;
    }
    return result;
  }
}
