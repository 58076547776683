import { Inject, Injectable } from '@angular/core';
import { isNil } from 'lodash-es';
import { environment } from '../environments/environment';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';

@Injectable({ providedIn: 'root' })
export class AsnOrders_ds_asn_orders_tasks_gridService {

  constructor(
    private utils: UtilsService,
    private settings: SettingsValuesService,
    ) { 
  }

  public async get(inParams: { order_id: number, full_text_search?: string, shipment_id: number, operation_code_ids?: number[], status_ids?: number[] }): 
  Promise<{ result: { Id?: number, ActualPackagedAmount?: number, ChainHead?: number, CompletedDateTime?: string, CreatedSysDateTime?: string, ExpectedPackagedAmount?: number, ModifiedSysUser?: string, Notes?: string, SerialNumberId?: number, ActualPackagedPack?: { ShortName?: string }, ExpectedPackagedPack?: { ShortName?: string }, Lot?: { Id?: number, LookupCode?: string, Material?: { ProjectId?: number, Project?: { Id?: number, LookupCode?: string } } }, Material?: { Id?: number, LookupCode?: string, ProjectId?: number, Project?: { Id?: number, LookupCode?: string } }, Project?: { Id?: number, LookupCode?: string }, OperationCode?: { Name?: string }, Status?: { Name?: string }, ActualSourceLicensePlate?: { Id?: number, LookupCode?: string }, ActualSourceLocation?: { Id?: number, Name?: string }, ActualTargetLicensePlate?: { Id?: number, LookupCode?: string }, ActualTargetLocation?: { Id?: number, Name?: string }, ExpectedSourceLicensePlate?: { Id?: number, LookupCode?: string }, ExpectedSourceLocation?: { Id?: number, Name?: string }, ExpectedTargetLicensePlate?: { Id?: number, LookupCode?: string }, ExpectedTargetLocation?: { Id?: number, Name?: string }, Warehouse?: { Name?: string }, Order?: { LookupCode?: string, OwnerReference?: string, VendorReference?: string }, SerialNumber?: { LookupCode?: string } }[] }> 
  {
    const missingRequiredInParams = [];
    if (isNil(inParams.order_id)) {
      missingRequiredInParams.push('\'order_id\'');
    }
    if (isNil(inParams.shipment_id)) {
      missingRequiredInParams.push('\'shipment_id\'');
    }
    if (missingRequiredInParams.length) {
    }
    let url = `${environment.backendUrl}api/`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.utils.http.post(url, body, options);
    
  }
  public async getList(inParams: { order_id: number, full_text_search?: string, shipment_id: number, operation_code_ids?: number[], status_ids?: number[], $top?: number, $skip?: number }): 
  Promise<{ result: { Id?: number, ActualPackagedAmount?: number, ChainHead?: number, CompletedDateTime?: string, CreatedSysDateTime?: string, ExpectedPackagedAmount?: number, ModifiedSysUser?: string, Notes?: string, SerialNumberId?: number, ActualPackagedPack?: { ShortName?: string }, ExpectedPackagedPack?: { ShortName?: string }, Lot?: { Id?: number, LookupCode?: string, Material?: { ProjectId?: number, Project?: { Id?: number, LookupCode?: string } } }, Material?: { Id?: number, LookupCode?: string, ProjectId?: number, Project?: { Id?: number, LookupCode?: string } }, Project?: { Id?: number, LookupCode?: string }, OperationCode?: { Name?: string }, Status?: { Name?: string }, ActualSourceLicensePlate?: { Id?: number, LookupCode?: string }, ActualSourceLocation?: { Id?: number, Name?: string }, ActualTargetLicensePlate?: { Id?: number, LookupCode?: string }, ActualTargetLocation?: { Id?: number, Name?: string }, ExpectedSourceLicensePlate?: { Id?: number, LookupCode?: string }, ExpectedSourceLocation?: { Id?: number, Name?: string }, ExpectedTargetLicensePlate?: { Id?: number, LookupCode?: string }, ExpectedTargetLocation?: { Id?: number, Name?: string }, Warehouse?: { Name?: string }, Order?: { LookupCode?: string, OwnerReference?: string, VendorReference?: string }, SerialNumber?: { LookupCode?: string } }[], totalCount: number }> 
  {
    const missingRequiredInParams = [];
    if (isNil(inParams.order_id)) {
      missingRequiredInParams.push('\'order_id\'');
    }
    if (isNil(inParams.shipment_id)) {
      missingRequiredInParams.push('\'shipment_id\'');
    }
    if (missingRequiredInParams.length) {
    }
    let url = `${environment.backendUrl}api/`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.utils.http.post(url, body, options);
    
  }
  public async getByKeys(inParams: { order_id: number, full_text_search?: string, shipment_id: number, operation_code_ids?: number[], status_ids?: number[], $keys: number[] }): 
  Promise<{ result: { Id?: number, ActualPackagedAmount?: number, ChainHead?: number, CompletedDateTime?: string, CreatedSysDateTime?: string, ExpectedPackagedAmount?: number, ModifiedSysUser?: string, Notes?: string, SerialNumberId?: number, ActualPackagedPack?: { ShortName?: string }, ExpectedPackagedPack?: { ShortName?: string }, Lot?: { Id?: number, LookupCode?: string, Material?: { ProjectId?: number, Project?: { Id?: number, LookupCode?: string } } }, Material?: { Id?: number, LookupCode?: string, ProjectId?: number, Project?: { Id?: number, LookupCode?: string } }, Project?: { Id?: number, LookupCode?: string }, OperationCode?: { Name?: string }, Status?: { Name?: string }, ActualSourceLicensePlate?: { Id?: number, LookupCode?: string }, ActualSourceLocation?: { Id?: number, Name?: string }, ActualTargetLicensePlate?: { Id?: number, LookupCode?: string }, ActualTargetLocation?: { Id?: number, Name?: string }, ExpectedSourceLicensePlate?: { Id?: number, LookupCode?: string }, ExpectedSourceLocation?: { Id?: number, Name?: string }, ExpectedTargetLicensePlate?: { Id?: number, LookupCode?: string }, ExpectedTargetLocation?: { Id?: number, Name?: string }, Warehouse?: { Name?: string }, Order?: { LookupCode?: string, OwnerReference?: string, VendorReference?: string }, SerialNumber?: { LookupCode?: string } }[] }> 
  {
    const missingRequiredInParams = [];
    if (isNil(inParams.order_id)) {
      missingRequiredInParams.push('\'order_id\'');
    }
    if (isNil(inParams.shipment_id)) {
      missingRequiredInParams.push('\'shipment_id\'');
    }
    if (isNil(inParams.$keys)) {
      missingRequiredInParams.push('\'$keys\'');
    }
    if (missingRequiredInParams.length) {
    }
    let url = `${environment.backendUrl}api/`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.utils.http.post(url, body, options);
    
  }


    
    hasMissingRequiredInParams(inParams: { 
      order_id: number, full_text_search?: string, shipment_id: number, operation_code_ids?: number[], status_ids?: number[]    }) {
        if(isNil(inParams.order_id)) {
          return true; 
        }
        if(isNil(inParams.shipment_id)) {
          return true; 
        }
      return false;
    }
}
