import { Inject, Injectable } from '@angular/core';
import { HttpResponse } from '@angular/common/http';
import { isNil, omit } from 'lodash-es';
import { environment } from '../environments/environment';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';

import { $frontendTypes } from './FootPrintApiManager.frontend.types'

@Injectable({ providedIn: 'root' })
export class FootPrintApiManager_get_outbound_orders_confirmationService {

  constructor(
    private utils: UtilsService,
    private settings: SettingsValuesService,
    ) { 
  }

  async run(inParams: { outbound_order_ids: number[] }): Promise<{ outbound_order_shipping_containers: { outbound_order?: $frontendTypes.FootPrintApiManager.public_order, shipping_info?: { shipment?: $frontendTypes.FootPrintApiManager.public_shipment, shipping_containers?: $frontendTypes.FootPrintApiManager.public_shipping_container[] }, message?: string }[], errors?: string[] }> 
  {
    const missingRequiredInParams = [];
    if (isNil(inParams.outbound_order_ids)) {
      missingRequiredInParams.push('\'outbound_order_ids\'');
    }
    if (missingRequiredInParams.length) {
    }
    let url = `${environment.backendUrl}api/`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.utils.http.post(url, body, options);
    
  }

}

