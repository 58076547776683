import { Inject, Injectable, Injector } from '@angular/core';

import { Utilities_FlowService } from './Utilities.flow.index';
import { Attachments_FlowService } from './Attachments.flow.index';
import { WarehouseTransfers_FlowService } from './WarehouseTransfers.flow.index';
import { Notifications_FlowService } from './Notifications.flow.index';
import { PackVerification_FlowService } from './PackVerification.flow.index';
import { InventoryTransfers_FlowService } from './InventoryTransfers.flow.index';
import { Reports_FlowService } from './Reports.flow.index';
import { PrintNode_FlowService } from './PrintNode.flow.index';
import { AsnOrders_FlowService } from './AsnOrders.flow.index';
import { SalesOrders_FlowService } from './SalesOrders.flow.index';
import { FootPrintApiManager_FlowService } from './FootPrintApiManager.flow.index';
import { Returns_FlowService } from './Returns.flow.index';
import { Cartonization_FlowService } from './Cartonization.flow.index';

import { app_create_shipment_attachmentService } from './app.flow.index';
import { app_create_shipping_container_attachmentService } from './app.flow.index';
import { app_custom_alerts_sendService } from './app.flow.index';
import { app_custom_close_eligible_inventory_transfers_flowService } from './app.flow.index';
import { app_custom_crud_batch_request_flowService } from './app.flow.index';
import { app_custom_get_eligible_container_types_flowService } from './app.flow.index';
import { app_custom_get_report_data_flowService } from './app.flow.index';
import { app_custom_get_shipment_quantities_to_cartonize_flowService } from './app.flow.index';
import { app_custom_manual_allocation_automationService } from './app.flow.index';
import { app_custom_submit_hoplite_inbound_receiptService } from './app.flow.index';
import { app_print_cloud_reportService } from './app.flow.index';
import { app_process_return_order_flowService } from './app.flow.index';
import { app_transfer_licenseplates_to_new_salesorder_flowService } from './app.flow.index';

import { $frontendTypes } from './app.frontend.types'

@Injectable({ providedIn: 'root' })
export class app_FlowService {

  constructor(
    private injector: Injector
  ) {
    this.Utilities = this.injector.get(Utilities_FlowService);
    this.Attachments = this.injector.get(Attachments_FlowService);
    this.WarehouseTransfers = this.injector.get(WarehouseTransfers_FlowService);
    this.Notifications = this.injector.get(Notifications_FlowService);
    this.PackVerification = this.injector.get(PackVerification_FlowService);
    this.InventoryTransfers = this.injector.get(InventoryTransfers_FlowService);
    this.Reports = this.injector.get(Reports_FlowService);
    this.PrintNode = this.injector.get(PrintNode_FlowService);
    this.AsnOrders = this.injector.get(AsnOrders_FlowService);
    this.SalesOrders = this.injector.get(SalesOrders_FlowService);
    this.FootPrintApiManager = this.injector.get(FootPrintApiManager_FlowService);
    this.Returns = this.injector.get(Returns_FlowService);
    this.Cartonization = this.injector.get(Cartonization_FlowService);
  }

    public Utilities: Utilities_FlowService;
    public Attachments: Attachments_FlowService;
    public WarehouseTransfers: WarehouseTransfers_FlowService;
    public Notifications: Notifications_FlowService;
    public PackVerification: PackVerification_FlowService;
    public InventoryTransfers: InventoryTransfers_FlowService;
    public Reports: Reports_FlowService;
    public PrintNode: PrintNode_FlowService;
    public AsnOrders: AsnOrders_FlowService;
    public SalesOrders: SalesOrders_FlowService;
    public FootPrintApiManager: FootPrintApiManager_FlowService;
    public Returns: Returns_FlowService;
    public Cartonization: Cartonization_FlowService;
  public app: app_FlowService = this;

  // injecting lazily in order to avoid circular dependencies
 
  private _create_shipment_attachment: app_create_shipment_attachmentService;
  public async create_shipment_attachment(inParams: { file_content: string, shipment_id: number }): Promise<void> {
    if(!this._create_shipment_attachment) { 
      this._create_shipment_attachment = this.injector.get(app_create_shipment_attachmentService);
    }
    return this._create_shipment_attachment.run(inParams);
  }
   
   

   
 
  private _create_shipping_container_attachment: app_create_shipping_container_attachmentService;
  public async create_shipping_container_attachment(inParams: { file_content: string, container_id: number }): Promise<void> {
    if(!this._create_shipping_container_attachment) { 
      this._create_shipping_container_attachment = this.injector.get(app_create_shipping_container_attachmentService);
    }
    return this._create_shipping_container_attachment.run(inParams);
  }
   
   

   
 
  private _custom_alerts_send: app_custom_alerts_sendService;
  public async custom_alerts_send(inParams: { integration_name: string, log_level: number, log_input: any, log_id: string }): Promise< { success?: boolean, messages?: any[] }> {
    if(!this._custom_alerts_send) { 
      this._custom_alerts_send = this.injector.get(app_custom_alerts_sendService);
    }
    return this._custom_alerts_send.run(inParams);
  }
   
   

   
 
  private _custom_close_eligible_inventory_transfers_flow: app_custom_close_eligible_inventory_transfers_flowService;
  public async custom_close_eligible_inventory_transfers_flow(inParams: {  }): Promise< { results?: { closed: { inventory_transfer_id: number }[], skipped: { inventory_transfer_id: number, reason: string }[] } }> {
    if(!this._custom_close_eligible_inventory_transfers_flow) { 
      this._custom_close_eligible_inventory_transfers_flow = this.injector.get(app_custom_close_eligible_inventory_transfers_flowService);
    }
    return this._custom_close_eligible_inventory_transfers_flow.run(inParams);
  }
   
   

   
 
  private _custom_crud_batch_request_flow: app_custom_crud_batch_request_flowService;
  public async custom_crud_batch_request_flow(inParams: { entities: { set?: string, keys?: { name?: string, value?: any }[], method?: string, properties?: any }[], withTransaction?: boolean }): Promise< { reasons?: string[], response?: any, message?: string }> {
    if(!this._custom_crud_batch_request_flow) { 
      this._custom_crud_batch_request_flow = this.injector.get(app_custom_crud_batch_request_flowService);
    }
    return this._custom_crud_batch_request_flow.run(inParams);
  }
   
   

   
 
  private _custom_get_eligible_container_types_flow: app_custom_get_eligible_container_types_flowService;
  public async custom_get_eligible_container_types_flow(inParams: { shipmentId: number, context: string }): Promise< { containerTypeIds?: number[], logs?: string[] }> {
    if(!this._custom_get_eligible_container_types_flow) { 
      this._custom_get_eligible_container_types_flow = this.injector.get(app_custom_get_eligible_container_types_flowService);
    }
    return this._custom_get_eligible_container_types_flow.run(inParams);
  }
   
   

   
 
  private _custom_get_report_data_flow: app_custom_get_report_data_flowService;
  public async custom_get_report_data_flow(inParams: { package_name: string, report_name: string, inputs?: any }): Promise< { report_data?: Blob }> {
    if(!this._custom_get_report_data_flow) { 
      this._custom_get_report_data_flow = this.injector.get(app_custom_get_report_data_flowService);
    }
    return this._custom_get_report_data_flow.run(inParams);
  }
   
   

   
 
  private _custom_get_shipment_quantities_to_cartonize_flow: app_custom_get_shipment_quantities_to_cartonize_flowService;
  public async custom_get_shipment_quantities_to_cartonize_flow(inParams: { shipmentId: number }): Promise< { reasons?: string[], quantities?: { orderId?: number, lineNumber?: number, materialId?: number, packagingId?: number, amount?: number, group?: string }[] }> {
    if(!this._custom_get_shipment_quantities_to_cartonize_flow) { 
      this._custom_get_shipment_quantities_to_cartonize_flow = this.injector.get(app_custom_get_shipment_quantities_to_cartonize_flowService);
    }
    return this._custom_get_shipment_quantities_to_cartonize_flow.run(inParams);
  }
   
   

   
 
  private _custom_manual_allocation_automation: app_custom_manual_allocation_automationService;
  public async custom_manual_allocation_automation(inParams: {  }): Promise<void> {
    if(!this._custom_manual_allocation_automation) { 
      this._custom_manual_allocation_automation = this.injector.get(app_custom_manual_allocation_automationService);
    }
    return this._custom_manual_allocation_automation.run(inParams);
  }
   
   

   
 
  private _custom_submit_hoplite_inbound_receipt: app_custom_submit_hoplite_inbound_receiptService;
  public async custom_submit_hoplite_inbound_receipt(inParams: { orderId: number }): Promise< { response?: any, reasons?: string[] }> {
    if(!this._custom_submit_hoplite_inbound_receipt) { 
      this._custom_submit_hoplite_inbound_receipt = this.injector.get(app_custom_submit_hoplite_inbound_receiptService);
    }
    return this._custom_submit_hoplite_inbound_receipt.run(inParams);
  }
   
   

   
 
  private _print_cloud_report: app_print_cloud_reportService;
  public async print_cloud_report(inParams: { packageName: string, reportName: string, reportInputs: any, printerName: string }): Promise< { reasons?: string[] }> {
    if(!this._print_cloud_report) { 
      this._print_cloud_report = this.injector.get(app_print_cloud_reportService);
    }
    return this._print_cloud_report.run(inParams);
  }
   
   

   
 
  private _process_return_order_flow: app_process_return_order_flowService;
  public async process_return_order_flow(inParams: { orderId: number }): Promise< { reasons?: string[], reflectedOrderId?: number }> {
    if(!this._process_return_order_flow) { 
      this._process_return_order_flow = this.injector.get(app_process_return_order_flowService);
    }
    return this._process_return_order_flow.run(inParams);
  }
   
   

   
 
  private _transfer_licenseplates_to_new_salesorder_flow: app_transfer_licenseplates_to_new_salesorder_flowService;
  public async transfer_licenseplates_to_new_salesorder_flow(inParams: { order_id: number }): Promise< { reasons?: string[] }> {
    if(!this._transfer_licenseplates_to_new_salesorder_flow) { 
      this._transfer_licenseplates_to_new_salesorder_flow = this.injector.get(app_transfer_licenseplates_to_new_salesorder_flowService);
    }
    return this._transfer_licenseplates_to_new_salesorder_flow.run(inParams);
  }
   
   

   
}
