import { Injectable } from '@angular/core';

@Injectable({providedIn: 'root'})
export class SettingsValuesService {

  private _SettingsService: any = {};
  private _isInitialized = false;

  public set SettingsService(value: any) {
    this._isInitialized = true;
    this._SettingsService = value;
  }
  public get SettingsService() {
    return this._SettingsService;
  }

  public get isInitialized() {
    return this._isInitialized;
  }

  public get FootPrintAPI() {
    return this._SettingsService['FootPrintAPI'];
  }
  public get Integration_name() {
    return this._SettingsService['Integration_name'];
  }
  public get mongoDbConnection() {
    return this._SettingsService['mongoDbConnection'];
  }
  public get PrintNodeKey() {
    return this._SettingsService['PrintNodeKey'];
  }

  public app: SettingsValuesService = this;

  public get Utilities() {
     return {
FootPrintAPI : this.FootPrintAPI,
MongoDB : this.mongoDbConnection
    };
  }
  public get Attachments() {
     return {
FootPrintAPI : this.FootPrintAPI,
MongoDB : this.mongoDbConnection
    };
  }
  public get WarehouseTransfers() {
     return {
FootPrintAPI : this.FootPrintAPI,
MongoDB : this.mongoDbConnection
    };
  }
  public get Notifications() {
     return {
FootPrintAPI : this.FootPrintAPI,
MongoDB : this.mongoDbConnection
    };
  }
  public get PackVerification() {
     return {
FootPrintAPI : this.FootPrintAPI,
MongoDB : this.mongoDbConnection
    };
  }
  public get InventoryTransfers() {
     return {
FootPrintAPI : this.FootPrintAPI,
MongoDB : this.mongoDbConnection
    };
  }
  public get Reports() {
     return {
FootPrintAPI : this.FootPrintAPI,
MongoDB : this.mongoDbConnection
    };
  }
  public get PrintNode() {
     return {
FootPrintAPI : this.FootPrintAPI,
MongoDB : this.mongoDbConnection,
PrintNodeKey : this.PrintNodeKey
    };
  }
  public get AsnOrders() {
     return {
FootPrintAPI : this.FootPrintAPI,
MongoDB : this.mongoDbConnection
    };
  }
  public get SalesOrders() {
     return {
FootPrintAPI : this.FootPrintAPI,
MongoDB : this.mongoDbConnection
    };
  }
  public get FootPrintApiManager() {
     return {
FootPrintAPI : this.FootPrintAPI,
MongoDB : this.mongoDbConnection
    };
  }
  public get Returns() {
     return {
FootPrintAPI : this.FootPrintAPI,
MongoDB : this.mongoDbConnection
    };
  }
  public get Cartonization() {
     return {
FootPrintAPI : this.FootPrintAPI,
MongoDB : this.mongoDbConnection
    };
  }
  public get Instructions() {
     return {
FootPrintAPI : this.FootPrintAPI,
MongoDB : this.mongoDbConnection
    };
  }
  public get SerialNumbers() {
     return {
FootPrintAPI : this.FootPrintAPI,
MongoDB : this.mongoDbConnection
    };
  }
  public get Owners() {
     return {
FootPrintAPI : this.FootPrintAPI,
MongoDB : this.mongoDbConnection
    };
  }
  public get Materials() {
     return {
FootPrintAPI : this.FootPrintAPI,
MongoDB : this.mongoDbConnection
    };
  }
  public get PurchaseOrders() {
     return {
FootPrintAPI : this.FootPrintAPI,
MongoDB : this.mongoDbConnection
    };
  }
  public get Inventory() {
     return {
FootPrintAPI : this.FootPrintAPI,
MongoDB : this.mongoDbConnection
    };
  }
  public get Invoices() {
     return {
FootPrintAPI : this.FootPrintAPI,
MongoDB : this.mongoDbConnection
    };
  }
}
